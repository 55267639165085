var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "login" },
    [
      _c(
        "section",
        { staticClass: "login__body" },
        [
          _vm.isReleaseInformation ? _c("GitReleaseInformation") : _vm._e(),
          _vm._v(" "),
          _c("FluidBlob"),
          _vm._v(" "),
          _c("div", { staticClass: "login-body__form" }, [_vm._t("form")], 2),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "Footer",
        { attrs: { "space-between": "" } },
        [
          _c("IconLogoName", {
            staticClass: "login__footer-logo",
            attrs: { width: "85.5", height: "24" },
            nativeOn: {
              click: function($event) {
                return _vm.onWebsiteOpen.apply(null, arguments)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }